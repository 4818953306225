import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby-link"

import Input from "./input"
import { Button } from "./button"
import media from "./media"

const Form = styled.form`
  background-color: black;
  padding: 2rem;
  margin: 5rem 0;
  ${media.tablet`margin-top: 7rem;`}
`

const SectionHeader = styled.h2`
  text-align: center;
  color: white;
  font-size: 3rem;
  margin-bottom: 2rem;
`
const FlexDiv = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Hi = props => {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <Form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <SectionHeader>{props.header}</SectionHeader>
      <FlexDiv>
        <Input
          css="margin-right: 1rem; margin-bottom: 1rem;"
          name="name"
          type="text"
          placeholder="What is your name"
          onChange={handleChange}
        ></Input>
        <Input
          name="email"
          type="email"
          placeholder="Enter email"
          css="margin-right: 1rem; margin-bottom: 1rem;"
          onChange={handleChange}
        ></Input>
        <Button css="margin-bottom:1rem;">Submit</Button>
      </FlexDiv>
    </Form>
  )
}

export default Hi
