import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHandsHelping,
  faSmile,
  faGlobeAsia,
} from "@fortawesome/free-solid-svg-icons"

import media from "./media"

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 5rem 3rem;
`

const BenefitHeader = styled.h2`
  font-size: 3rem;
  margin-bottom: 2rem;
`

const BenefitsDiv = styled.div`
  justify-content: space-between;
  display: flex;
  ${media.phone`flex-direction: column;`}
`
const H4 = styled.h4`
  margin-top: 1rem;
`

const Benefit = styled.div`
  width: 400px;
  ${media.phone`width: 100%;`}
  ${media.phone`margin-bottom: 2rem;`}
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
`

const Benefits = props => (
  <StyledSection>
    <BenefitHeader>What we bring.</BenefitHeader>
    <BenefitsDiv>
      <Benefit>
        <FontAwesomeIcon icon={faHandsHelping} size="6x" />
        <H4>Expert Guidance & Support</H4>
        <p>
          We'll help you assess whether or not offshoring is right for you and
          develop a concise plan of action to achieve your outcomes.
        </p>
      </Benefit>
      <Benefit>
        <FontAwesomeIcon icon={faSmile} size="6x" />
        <H4>We know how to build teams</H4>
        <p>
          Our team has over 15 years of collective experience building offshore
          teams. Ones that work cohesively with your existing team.
        </p>
      </Benefit>
      <Benefit>
        <FontAwesomeIcon icon={faGlobeAsia} size="6x" />
        <H4>We know Manila inside and out</H4>
        <p>
          We've spent extensive time working with teams in Manila that operate
          in various ways. Let us help you get started and running effectively.
        </p>
      </Benefit>
    </BenefitsDiv>
  </StyledSection>
)

export default Benefits
