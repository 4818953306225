import React from "react"
import styled from "styled-components"

import easy from "../images/easy.jpg"
import media from "./media"

const ImageDiv = styled.div`
  position: relative;
  height: 100vh;
  background: center no-repeat url(${easy});
`

const H1 = styled.h2`
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.8);
  color: white;
  font-family: "SpaceGrotesk";
  font-size: 4rem;
  ${media.phone`font-size: 3rem;`}
  position: absolute;
  top: 70px;
  left: 120px;
  ${media.phone`left: 80px;`}
`
const R = styled.span`
  color: #ff4136;
`

const Marketing = styled.ol`
  top: 250px;
  left: 120px;
  ${media.phone`left: 80px;`}
  list-style-type: decimal;
  list-style-position: inside;
  position: absolute;
`

const Li = styled.li`
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.8);
  color: white;
  font-family: "SpaceGrotesk";
  font-size: 2rem;
  ${media.tablet`font-size: 1.5rem;`}
  ${media.phone`margin-right: 3rem;`}
  margin-bottom: 3rem;
  margin-right: 4rem;
`

const Easy = () => {
  return (
    <ImageDiv>
      <H1>
        How does it work<R>?</R>
      </H1>
      <Marketing>
        <Li>Fill in the contact form.</Li>
        <Li>We'll get in touch to set up a time for your first consultation</Li>
        <Li>
          {" "}
          We'll provide you with an obligation-free proposal to grow your team
          today.
        </Li>
      </Marketing>
    </ImageDiv>
  )
}

export default Easy
