import React from "react"
import styled from "styled-components"

const SectionDiv = styled.div`
  padding: 2rem;
  margin: 5rem 3rem;
`

const SectionHeader = styled.h2`
  font-size: 3rem;
  margin-bottom: 2rem;
`

const SectionP = styled.p`
  font-size: 1.5rem;
`

const RedLine = styled.span`
  color: #ff4136;
`

const Section = ({ redline, header, text }) => {
  return (
    <SectionDiv>
      <SectionHeader>
        <RedLine>{redline}</RedLine> {header}
      </SectionHeader>
      <SectionP>{text}</SectionP>
    </SectionDiv>
  )
}

export default Section
